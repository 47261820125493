<template>
  <div class="flex flex-col w-full">
    <Input
      :label="$t('signIn.emailAddress')"
      :onKeyUpHandler="setEmail"
      :placeholder="$t('signIn.emailPlaceholder')"
      name="email"
      type="email"
    />
    <ErrorText v-show="errorEmail" :errorMessage="errorEmail" />
    <Input
      :label="$t('signIn.password')"
      :onKeyUpHandler="setPassword"
      class="mt-5"
      name="password"
      placeholder=""
      type="password"
    />
    <ErrorText v-show="errorPassword" :errorMessage="errorPassword" />
    <span v-if="loginError" class="text-cs-red block mt-2 self-start">{{ $t(loginError) }}</span>
    <CommonLink class="mt-3 self-start dark:text-cs-light-blue" title="Forgot Password?" to="/user/password"
      >{{ $t('signIn.forgotPassword') }}
    </CommonLink>
    <Button
      :disabled="buttonDisabled"
      :isLoading="loading"
      :onClick="onLogin"
      :text="$t('signIn.login')"
      class="mt-5 w-full"
      variant="light"
    />
    <LoginSocialButtons :isLogin="true" :isModal="isModal" />
  </div>
</template>

<script lang="ts" setup>
import {login} from '~/composables/auth/useAuth'
import {LANGUAGE_COOKIE} from '~/constants/i18n'
import {GTM_EVENT_GUEST_MODAL_LOGIN_EMAIL, GTM_EVENT_LOGIN, GTM_EVENT_TYPE_SUBMIT} from '~/constants/gtmEvent'
import {SESSION_URL_REDIRECT} from '~/constants/common'
import {SignInEvent} from '~/types/log.d'
import ErrorText from '~/components/ui/atoms/ErrorText.vue'
import {isValidEmail} from '~/util/email'

interface ComponentProps {
  isModal?: boolean
}

const props = defineProps<ComponentProps>()

const {$eventTracker, $monitor} = useNuxtApp()
const {locale} = useI18n()
const languageCookie = useCookie(LANGUAGE_COOKIE)
const urlRedirectCookie = useCookie(SESSION_URL_REDIRECT)
const i18n = useI18n()

const email = ref<string>('')
const errorEmail = ref<string | null>(null)
const password = ref<string>('')
const errorPassword = ref<string | null>(null)
const loginError = ref<string | null>(null)
const loading = ref<boolean>(false)
const buttonDisabled = ref<boolean>(true)

const emit = defineEmits(['loginSuccess'])

const MINIMUM_PASSWORD_LENGTH = 4

const onLogin = async () => {
  try {
    // Validate email format before send to server
    const isValidForm = validateForm()
    if (!isValidForm) {
      return
    }

    loading.value = true
    loginError.value = null
    const {error} = await login(email.value, password.value, locale, languageCookie)
    if (error) {
      const statusCode = error.value?.statusCode
      if (isLoginErrorUserPassword(statusCode)) {
        loginError.value = 'signIn.userNotFound'
      } else {
        loginError.value = error.data?.message || 'common.error'
      }

      $monitor.criticalEvent(SignInEvent, {
        success: false,
        code: statusCode,
        message: 'User has failed to login',
        email: email.value
      })
    } else {
      if (props.isModal) {
        $eventTracker.triggerEvent(GTM_EVENT_GUEST_MODAL_LOGIN_EMAIL, GTM_EVENT_TYPE_SUBMIT)
      } else {
        $eventTracker.triggerEvent(GTM_EVENT_LOGIN, GTM_EVENT_TYPE_SUBMIT)
      }

      $monitor.criticalEvent(SignInEvent, {
        success: true,
        code: 200,
        message: `User has successfully sign in`,
        email: email.value
      })

      navigateToNextPage()
    }
  } catch (error) {
    //
  } finally {
    loading.value = false
  }
}

const isLoginErrorUserPassword = (statusCode: number) => {
  return statusCode === 401 || statusCode === 404 || statusCode === 422
}

const setEmail = (text: string) => {
  email.value = text
  checkButtonDisabled()
}

const setPassword = (text: string) => {
  password.value = text
  checkButtonDisabled()
}

const checkButtonDisabled = () => {
  buttonDisabled.value = email.value === '' || password.value === ''
}

const validateForm = () => {
  let isValid = true
  const emailIsValid = isValidEmail(email.value)
  if (!emailIsValid) {
    errorEmail.value = i18n.t('common.emailFormatError')
    isValid = false
  } else {
    errorEmail.value = null
  }

  // Validate password length
  if (!password.value || password.value.length < MINIMUM_PASSWORD_LENGTH) {
    errorPassword.value = i18n.t('signIn.passwordLengthError', {number: MINIMUM_PASSWORD_LENGTH})
    isValid = false
  } else {
    errorPassword.value = null
  }

  return isValid
}

// Redirect to previously visited url or to /
const navigateToNextPage = () => {
  if (props.isModal) {
    reloadNuxtApp()
  } else {
    emit('loginSuccess')
    if (urlRedirectCookie.value) {
      navigateTo(urlRedirectCookie.value)
      urlRedirectCookie.value = null
    } else {
      navigateTo('/')
    }
  }
}
</script>
